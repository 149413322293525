<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Solicitud de Crédito </strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row >
                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: <b-badge @click="ShowModalClient" variant="primary">Nuevo</b-badge></label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Crédito:">
                    <b-form-input class="text-center" v-model="credit.code" disabled></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Crédito:">
                    <b-form-select @change="ListFinancialData" v-model="credit.id_product" :options="products"></b-form-select>
                    <small v-if="errors.id_product"  class="form-text text-danger" >Seleccione un Crédito</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-select v-model="credit.id_financial_data" :options="financial_data"></b-form-select>
                    <small v-if="errors.id_financial_data"  class="form-text text-danger" >Seleccione una TEA</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Programa:">
                    <b-form-select v-model="credit.id_product_type" :options="products_type"></b-form-select>
                    <small v-if="errors.id_product_type"  class="form-text text-danger" >Seleccione un Programa</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input class="text-right" v-model="credit.amount" type="number" step="any"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cuotas:">
                    <b-form-input class="text-center" v-model="credit.dues" type="number" step="any"></b-form-input>
                    <small v-if="errors.dues"  class="form-text text-danger" >Ingrese una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input class="text-center" v-model="credit.disbursement_date"  type="date" ></b-form-input>
                    <small v-if="errors.disbursement_date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input class="text-center" v-model="credit.payment_date"  type="date" ></b-form-input>
                    <small v-if="errors.payment_date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2" v-if="credit.id_product == 2">
                  <b-form-group label=".">
                    <b-button type="button" variant="warning" @click="ShowModalPaymentCapital" class="form-control" >Pago del Capital</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" @click="ValidateSimulate" class="form-control" variant="primary" >Generar Cronograma</b-button>
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row class="row justify-content-center">
                <b-col md="11">
                  <b-form-group >
                    <label>Garantes: <b-badge @click="ShowModalGuarantor" variant="primary">Nuevo</b-badge></label>
                    <v-select placeholder="Seleccione un garante" class="w-100" :filterable="false" label="text" v-model="guarantor" @search="SearchGuarantors" :options="guarantors"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button type="button" @click="AddGuarantor" variant="primary" class="form-control">+</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="10%" class="text-center">T. D.</th>
                          <th width="15%" class="text-center">Nro Documento</th>
                          <th width="65%" class="text-center">Nombres</th>
                          <th width="5%" class="text-center">Acc.</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit.guarantors" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-left"> {{ CodeDocumentType(item.document_type)  }}</td>
                          <td class="text-left"> {{ item.document_number  }}</td>
                          <td class="text-left"> {{ item.name  }}</td>
                          <td class="text-center"><b-button type="button" @click="DeleteGuarantor(it)" variant="danger"><i class="fas fa-trash"></i></b-button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="w-100 text-center">
                    <small v-if="errors.guarantor"  class="form-text text-danger" >Ingrese un garante</small>
                  </div>
                  
                </b-col>
              </b-row>
             
              <b-row class="row justify-content-center">
                <b-col md="4">
                  <b-form-group label="Anexos:">
                     <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.annexes"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Documentos Adjuntados:">
                  <b-form-checkbox-group  v-model="credit.uploaded_documents" :options="requirements" name="flavour-1"></b-form-checkbox-group>
                  <small v-if="errors.uploaded_documents"  class="form-text text-danger" >Seleccione los documentos adjuntados</small>
                </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observaciones:">
                    <b-form-textarea  v-model="credit.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th width="3%" class="text-center">#</th>
                      <th width="17%" class="text-center">Cuota</th>
                      <th width="17%" class="text-center">Fecha</th>
                      <th width="17%" class="text-center">Capital</th>
                      <th width="17%" class="text-center">Interes</th>
                      <th width="17%" class="text-center">Saldo</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item, it) in loan_detail" :key="it">
                    <tr>
                      <td class="text-center">{{ item.quota_number }}</td>
                      <td class="text-right"> {{ item.quota  }}</td>
                      <td class="text-center"> {{ item.date  }}</td>
                      <td class="text-right"> {{ item.capital_balance  }}</td>
                      <td class="text-right"> {{ item.interest  }}</td>
                      <td class="text-right"> {{ item.balance  }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>



              <b-row class="row justify-content-center">
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>

            </b-form>

          



            <b-modal size="md" hide-footer v-model="modal_payment_capital" class="" title="Pago del Capital">
              <b-row class="row justify-content-center">

                <b-col md="4">
                  <b-form-group label="Cuota:">
                    <b-form-input v-model="payment_capital.dues" placeholder="0" type="number"  class="text-center"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Monto:">
                    <b-form-input v-model="payment_capital.amount" placeholder="0.00" type="number" step="any" class="text-right"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label=".">
                    <b-button type="button" @click="AddPaymentCapital" variant="primary" class="form-control">Agregar</b-button>
                  </b-form-group>
                </b-col>
                
        
              </b-row>

              <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="40%" class="text-center">Cuota</th>
                    <th width="50%" class="text-center">Monto</th>
                    <th width="10%" class="text-center">Acc.</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in credit.payment_capital" :key="it">
                  <tr>
                    <td class="text-center">
                      <b-form-input type="number" class="text-right" step="any" v-model="item.dues" @change="EditPaymentCapital(it)"></b-form-input>
                    </td>
                    <td class="text-right">
                      <b-form-input type="number" class="text-right" step="any" v-model="item.amount" @change="EditPaymentCapital(it)"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-button type="button" @click="DeletePaymentCapital(it)" size="sm" variant="danger"><i class="fas fa-trash"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </b-modal>


            <ModalClients/>
            <ModalGuarantor/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import ModalClients from './../components/ModalClient'
import ModalGuarantor from './../components/ModalGuarantor'
export default {
  name: "CreditAdd",
  components:{
    vSelect,
    ModalClients,
    ModalGuarantor,
  },
  data() {
    return {
      module:'Credit',
      role:2,
      credit: {
        id_branch_office:'',
        id_user:'',
        id_client:'',
        id_product:'',
        id_product_type:'',
        id_financial_data:'',
        code:'',
        amount:'',
        dues:'',
        disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
        payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        days_of_grace: 0,
        payment_capital:'',
        annexes:'',
        uploaded_documents:[],
        observation:'',
        id_disbursement:'',
        id_user_approver:'',
        credit_status:'',
        state:'',
        payment_capital: [],
        guarantors:[],
	
      },
      products: [],
      products_type:[],
      financial_data: [{value:"", text:"- Seleccione una opción -"}],
      payment_capital:{
        dues: '',
        amount: '',
      },
      requirements:[],
      client: null,
      clients: [],
      file:null,
      guarantor: null,
      guarantors: [],
      errors:{
        id_client: false,
        id_product: false,
        id_financial_data: false,
        amount: false,
        dues: false,
        payment_date: false,
        disbursement_date: false,
        payment_capital: false,
        guarantor: false,
        annexes: false,
        uploaded_documents: false,
      },
      allowed_age: {
        minimum_age:0,
        maximum_age:0,
      },
      loan_detail: [],

      modal_payment_capital: false,
     
    };
  },
  mounted() {
    EventBus.$on('GetDataClient', (data) => {
      const birth_date = moment(data.birth_date);
      const date_now = moment(this.credit.date_now);
      let client_age = 0;
      if (birth_date.isValid()) {
        client_age = date_now.diff(birth_date, 'years');
      }
      this.client = {value:data.id_client,text:data.name+" - "+data.document_number, age : client_age};
    });
    EventBus.$on('GetDataGuarantor', (data) => {
      const birth_date = moment(data.birth_date);
      const date_now = moment(this.credit.date_now);
      let guarantor_age = 0;
      if (birth_date.isValid()) {
        guarantor_age = date_now.diff(birth_date, 'years');
      }
      this.guarantor = {
        value:data.id_guarantor,
        text:data.name+" - "+data.document_number, 
        name: data.name,
        document_type: data.document_type,
        document_number: data.document_number,
        age: guarantor_age
      };

    });
    this.ListProducts();
    this.ListProductsType();
    this.ListRequirements();
    this.GetCorrelative();
    this.GetAllowedAges();
  },
  methods: {

    ListProducts,
    ListProductsType,
    ListRequirements,
    ListFinancialData,
    GetCorrelative,
    Validate,
    AddCredit,
    ValidateSimulate,
    Simulation,

    ShowModalPaymentCapital,
    AddPaymentCapital,
    EditPaymentCapital,
    DeletePaymentCapital,

    CleanLoan,

    SearchClients,
    SearchGuarantors,

    ShowModalClient,
    ShowModalGuarantor,

    AddGuarantor,
    DeleteGuarantor,
    CodeDocumentType,

    onFileChange,
    GetAllowedAges,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function GetAllowedAges() {

let url = this.url_base + "data/client-get-allowed-ages";
let me = this;
axios({
    method: "GET",
    url: url,
  }).then(function (response) {
   if (response.data.status == 200) {
      me.allowed_age.minimum_age = parseFloat(response.data.result.minimum_age);
      me.allowed_age.maximum_age = parseFloat(response.data.result.maximum_age);
   }
})
}


function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function CleanLoan() {
  this.loan_detail = [];
}

function ShowModalPaymentCapital() {
  this.modal_payment_capital = true;
}

function AddPaymentCapital() {

  if (this.payment_capital.dues.length == 0) {
    Swal.fire({ icon: 'error', text: 'Ingrese una cuota', timer: 3000,})
    return false;
  }

  if (this.payment_capital.amount.length == 0) {
    Swal.fire({ icon: 'error', text: 'Ingrese un monto', timer: 3000,})
    return false;
  }

  this.payment_capital.amount = parseFloat(this.payment_capital.amount).toFixed(2);

  let payment = {
    dues: this.payment_capital.dues,
    amount: this.payment_capital.amount,
  }

  this.credit.payment_capital.push(payment);

  this.payment_capital.dues = '';
  this.payment_capital.amount = '';

}

function EditPaymentCapital(index) {
  this.credit.payment_capital[index].dues =  this.credit.payment_capital[index].dues.length == 0 ? 0 : this.credit.payment_capital[index].dues;
  this.credit.payment_capital[index].amount =  this.credit.payment_capital[index].amount.length == 0 ? 0 : this.credit.payment_capital[index].amount;

  this.credit.payment_capital[index].dues = parseFloat(this.credit.payment_capital[index].dues);
  this.credit.payment_capital[index].amount = parseFloat(this.credit.payment_capital[index].amount).toFixed(2);
}

function DeletePaymentCapital(index) {
  this.credit.payment_capital.splice(index, 1);
}

function ListProducts() {

  let me = this;
  let url = me.url_base + "product/actives";
  me.products = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products.push({value: element.id_product, text: element.name });
      });
    }

  });
}

function ListProductsType() {
  let me = this;
  let url = me.url_base + "product/list-product-type";
  me.products_type = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products_type.push({value: element.id_product_type, text: element.name });
      });
    }

  });
}

function ListFinancialData() {

  let me = this;
  me.financial_data = [{ value: '', text:'- Seleccione una opcion - '}];
  me.credit.id_financial_data = '';
  me.credit.payment_capital = [];
  if (me.credit.id_product.length == 0) {
    return false;
  }
  let url = me.url_base + "financial-data/actives/"+me.credit.id_product;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.financial_data.push({value: element.id_financial_data, text: element.tea + "%" });
        if (element.default == 1) {
          me.credit.id_financial_data = element.id_financial_data;
        }
      });
    }

  });
}

function ValidateSimulate() {

  this.errors.id_product = this.credit.id_product.length == 0 ? true : false;
  this.errors.id_financial_data = this.credit.id_financial_data.length == 0 ? true : false;
  this.errors.amount = this.credit.amount.length == 0 ? true : false;
  this.errors.dues = this.credit.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.credit.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.credit.payment_date.length == 0 ? true : false;
  // this.errors.payment_capital = this.credit.payment_capital.length == 0 ? true : false;

  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_capital) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  this.Simulation();
}

function Simulation() {
  let me = this;
  let url = me.url_base + "credit/simulate";
  let data = me.credit;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.loan_detail = response.data.result;
    }else{
      if (me.credit.id_product == 2) {
        me.modal_payment_capital = true;
      }
      Swal.fire({ icon: 'error', text: response.data.message, timer: 2000,}); 
    }

  });
}

function Validate() {

 
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_product = this.credit.id_product.length == 0 ? true : false;
  this.errors.id_product_type = this.credit.id_product_type.length == 0 ? true : false;
  this.errors.id_financial_data = this.credit.id_financial_data.length == 0 ? true : false;
  this.errors.amount = this.credit.amount.length == 0 ? true : false;
  this.errors.dues = this.credit.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.credit.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.credit.payment_date.length == 0 ? true : false;
  // this.errors.payment_capital = this.credit.payment_capital.length == 0 ? true : false;

  this.errors.annexes = this.credit.annexes.length == 0 ? true : false;
  this.errors.uploaded_documents = this.credit.uploaded_documents.length == 0 ? true : false;

  
  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_product_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_capital) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if(this.credit.amount.length > 0){
    if (parseFloat(this.credit.amount) > 1500 ) {
      if (this.credit.guarantors.length == 0) {
        this.validate = true;
        Swal.fire({ icon: 'warning', text: 'Se requiere que ingrese un garante', timer: 2000,}); 
        return false;
      }
    } 
  }
  if (this.errors.annexes) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.uploaded_documents) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let title = "Esta seguro de registrar la solicitud de crédito ?";
  if (parseFloat(this.client.age) > parseFloat(this.allowed_age.maximum_age)) {
      title = "Esta seguro de registrar la solicitud de crédito, con un cliente de "+this.client.age+" años?";
  }

  let me = this;
  Swal.fire({
    title: title,
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCredit();
    }
  });
}

function AddCredit() {
  let me = this;
  let data = new FormData();
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_user", this.user.id_user);
  data.append("id_client", this.client.value);
  data.append("id_product", this.credit.id_product);
  data.append("id_product_type", this.credit.id_product_type);
  data.append("id_financial_data", this.credit.id_financial_data);
  data.append("code", this.credit.code);
  data.append("amount", this.credit.amount);
  data.append("dues", this.credit.dues);
  data.append("disbursement_date", this.credit.disbursement_date);
  data.append("payment_date", this.credit.payment_date);
  data.append("payment_capital", JSON.stringify(this.credit.payment_capital));
  data.append("annexes", this.credit.annexes);
  data.append("uploaded_documents", JSON.stringify(this.credit.uploaded_documents));
  data.append("observation", this.credit.observation);
  data.append("guarantors", JSON.stringify(this.credit.guarantors));

  let url = me.url_base + "credit/add";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({ name: "CreditList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ListRequirements() {

  let me = this;
  let url = me.url_base + "requirement/list-requirement-by-module/1";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        response.data.result.forEach(element => {
          me.requirements.push({value: element.id_requirement, text: element.name})
        });
      }
    });
}

function GetCorrelative() {
  let me = this;
  let url = me.url_base + "data/get-correlative/Credit";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit.code = response.data.result.number;
      }
    });
}

function ShowModalClient() {
  EventBus.$emit('ModalClientsShow');
}

function ShowModalGuarantor() {
  EventBus.$emit('ModalGuarantorShow');
}

function SearchGuarantors(search, loading) {
  
  let me = this;
   let url = this.url_base + "guarantor/search-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.guarantors = response.data.result;
           loading(false);
     })
   }
}

function AddGuarantor() {
  if (this.guarantor == null) {
    return false;
  }

  let guarantor = {
    id_guarantor: this.guarantor.value,
    name: this.guarantor.name,
    document_type: this.guarantor.document_type,
    document_number: this.guarantor.document_number,
  }
  
  this.credit.guarantors.push(guarantor)
}

function DeleteGuarantor(index) {
  this.credit.guarantors.splice(index, 1);
}

function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code.toString());
}

function onFileChange(e) {
  this.credit.annexes = e.target.files[0];
}
</script>